import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicNotFound = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query usePrismicNotFound {
       allPrismicNotfound(filter: {tags: {in: "not_found"}}) {
        nodes {
          lang
          data {
            title
            subtitle {
              text
            }
            linktext
            description
            description_1
            description_2
            description_3
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicNotfound.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        title: response.title,
        subTitle: response.subtitle.text,
        linkText: response.linktext,
        description: response.description,
        description_1: response.description_1,
        description_2: response.description_2,
        description_3: response.description_3,
    }
}

export default usePrismicNotFound;
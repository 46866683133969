import * as React from "react"
import { Link } from "gatsby"
import usePrismicNotFound from "../hooks/usePrismicNotFound";
import Seo from "../components/SEO";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  const data = usePrismicNotFound()
  return (
    <main style={pageStyles}>

      <Seo
          title={data.title}
          description={data.title}
          link={'https://www.fsm.am'}
      />

      <h1 style={headingStyles}>{data.subTitle}</h1>
      <p style={paragraphStyles}>
        {data.description}{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        {data.description_1}
        <br />
        {/*{process.env.NODE_ENV === "development" ? (*/}
          <>
            <br />
            {data.description_2} <code style={codeStyles}>{data.description_3}</code>.
            <br />
          </>
        {/*) : null}*/}
        <br />
        <Link to="/">{data.linkText}</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
